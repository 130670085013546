import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import bgImageMobile from 'src/assets/images/temps/mobile/bg-imobiliario-comercial-mobile.jpg'
import bgImageDesktop from 'src/assets/images/temps/desktop/bg-imobiliario-comercial-desktop.jpg'

export const ContentHeader = styled.section`
  display: flex;
  align-items: end;
  height: 470px;
  width: auto;
  background-image: url(${bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &:before {
    content: '';
    background-image: linear-gradient(to right, rgba(140, 47, 12, 0.8) 60%, rgba(140, 47, 12, 0.5));
    height: 100%;
    position: absolute;
    width: 100%;
  }
  
  @media ${device.tablet} {
    background-image: url(${bgImageDesktop});
    background-repeat: no-repeat;
    background-position: top;
  }

`
