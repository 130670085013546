import React from 'react'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

import CallToAction from 'src/components/CallToAction'
import CondicoesEspeciais from '../condicoes-especiais/_index'
import Vantagens from '../vantagens/_index'
import ComoAcontece from '../como-acontece/_index'

const Sidebar = () => {
    return (
      <ContentWrapper>
        <div className='col-12 pt-5 px-0'>
          <CondicoesEspeciais />
          <Vantagens />
          <ComoAcontece />
          <SideBar className='col-12 col-lg-4 px-4 px-lg-2'>
            <div className='position-sticky'>
              <CallToAction
                chat='true'
                section='dobra_02'
                elementName='Solicite sua proposta'
                sectionName='Aqui, você tem condições especiais e vantagens que valem a pena'
                redirectUrl='https://imobiliario.inter.co/financiamento-imobiliario/'
              >
                <a
                  href='https://imobiliario.inter.co/financiamento-imobiliario/'
                  rel='noreferrer'
                  target='_blank'
                  title='Solicite sua proposta'
                  className='btn btn--orange btn--lg btn--block mb-4'
                > Solicite sua proposta
                </a>
              </CallToAction>
            </div>
          </SideBar>
        </div>
      </ContentWrapper>
    )
}

export default Sidebar
