import React from 'react'

import imgBtNumberOne from 'src/assets/images/svg/home-equity-landing/bt-number-one.svg'
import imgPedidoDeCredito from 'src/assets/images/svg/home-equity-landing/pedido-de-credito.svg'
import imgBtNumberTwo from 'src/assets/images/svg/home-equity-landing/bt-number-two.svg'
import imgAnaliseFinanceira from 'src/assets/images/svg/home-equity-landing/analise-financeira.svg'
import imgBtNumberThree from 'src/assets/images/svg/home-equity-landing/bt-number-three.svg'
import imgDocumentacaoAssinatura from 'src/assets/images/svg/home-equity-landing/documentacao-e-assinatura.svg'
import imgBtNumberFour from 'src/assets/images/svg/home-equity-landing/bt-number-four.svg'
import imgLiberacaoDeCredito from 'src/assets/images/svg/home-equity-landing/liberacao-de-credito.svg'

const ComoAcontece = () => {
  return (
    <section id='como-acontece'>
      <div className='container px-3'>
        <div className='col-12 col-lg-8'>
          <h3 className='fs-md-40 mt-5'>Passo a passo para contratação</h3>
          <div className='row d-flex mt-4 my-lg-5'>
            <div className='col-12 mb-4'>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-5'>
                  <img src={imgBtNumberOne} className='ml-n3' alt='Passo um' width='82' height='82' />
                  <h4 className='fs-22 fw-700 mt-2'>Simule sua proposta</h4>
                  <p className='fs-16 text-gray--600 mt-n3'>Você faz uma simulação pelo nosso site e pede uma proposta. Um dos nossos consultores entra em contato com você, sem compromisso.</p>
                </div>
                <div className='col-12 col-md-6 order-last mt-3'>
                  <img src={imgPedidoDeCredito} className='m-auto d-block d-md-inline' alt='Envie seus documentos' width='336' height='197' />
                </div>
              </div>
            </div>
            <div className='col-12 mb-4'>
              <div className='row'>
                <div className='col-12 col-md-6 order-md-last'>
                  <img src={imgBtNumberTwo} className='ml-n3' alt='Passo Dois' width='82' height='82' />
                  <h4 className='fs-22 fw-700 mt-2'>Envie seus documentos</h4>
                  <p className='fs-16 text-gray--600 mt-n3'>Gostou da proposta? Então, é hora de enviar seus dados e documentos para o seu consultor do Inter.</p>
                </div>
                <div className='col-12 col-md-6 col-lg-5 mt-3'>
                  <img src={imgAnaliseFinanceira} className='m-auto d-block d-md-inline' alt='Envie seus documentos' width='326' height='200' />
                </div>
              </div>
            </div>
            <div className='col-12 mb-4'>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg-5'>
                  <img src={imgBtNumberThree} className='ml-n3' alt='Passo três' width='82' height='82' />
                  <h4 className='fs-22 fw-700 mt-2'>Análise de crédito</h4>
                  <p className='fs-16 text-gray--600 mt-n3'>Nesse momento, nós analisamos seus dados e os documentos que você enviou no passo 2.</p>
                </div>
                <div className='col-12 col-md-6 order-last mt-4'>
                  <img src={imgDocumentacaoAssinatura} className='m-auto d-block d-md-inline' alt='Análise de crédito' width='329' height='200' />
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12 col-md-6 order-md-last'>
                  <img src={imgBtNumberFour} className='ml-n3' alt='Passo quatro' width='82' height='82' />
                  <h4 className='fs-22 fw-700 mt-2'>Emissão do contrato e liberação do empréstimo</h4>
                  <p className='fs-16 text-gray--600 mt-n3'>Se tiver tudo certo, preparamos e emitimos o seu contrato. Com o contrato feito e assinado, a liberação do seu empréstimo acontece rapidinho!</p>
                </div>
                <div className='col-12 col-md-6 col-lg-5 mt-4 mt-md-0'>
                  <img src={imgLiberacaoDeCredito} className='m-auto d-block d-md-inline' alt='Emissão de contrato e liberação de empréstimo' width='330' height='200' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComoAcontece
