import React from 'react'

// Images
import imgSeguros from 'src/assets/images/svg/imobiliario/seguros.svg'
import imgZero from 'src/assets/images/svg/imobiliario/zero.svg'

const Vantagens = () => {
  return (
    <section id='vantagens' className='bg-gray--100'>
      <div className='container'>
        <div className='col-12 col-lg-8 py-5 px-0'>
          <h3 className='fs-md-40 mb-5'>Parcelas mais suaves</h3>
          <div className='row my-4'>
            <div className='col-12 col-md-5 mb-4'>
              <div className='d-md-flex align-items-center mb-3'>
                <div className='icon--badge badge--large bg-orange mb-2 float-left'>
                  <img src={imgSeguros} alt='Seguro Financiamento protegido' width='40' height='43' />
                </div>
                <h4 className='fs-26 fs-md-30 mb-0'>Seu <br />financiamento protegido</h4>
              </div>
              <p>Aqui, os seguros obrigatórios já estão inclusos nas parcelas e são mais em conta. Compare!</p>
            </div>
            <div className='col-12 col-md-6 offset-md-1'>
              <div className='d-md-flex align-items-center ml-2 mb-3'>
                <div className='icon--badge badge--large bg-orange mb-2 float-left'>
                  <img src={imgZero} alt='Sem tarifas, sem asteriscos, sem surpresas' width='34' height='50' />
                </div>
                <h4 className='fs-26 fs-md-30 mb-0'>Sem tarifas, sem asteriscos, sem surpresas!</h4>
              </div>
              <p>Trazendo o seu financiamento para o Inter, você ainda aproveita todas as vantagens de ter uma Conta Digital totalmente gratuita!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vantagens
