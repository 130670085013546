import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

import pageContext from './pageContext.json'

import Header from './sections/header/_index'
import Sidebar from './sections/sidebar/_index'

const EmpresasFinanciamentoImobiliarioComercial = () => {
  return (
    <Wrapper>
      <Layout isPJ pageContext={pageContext}>
        <Header />
        <Sidebar />
      </Layout>
    </Wrapper>
  )
}

export default EmpresasFinanciamentoImobiliarioComercial
