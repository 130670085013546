import React, { useMemo } from 'react'
import Loadable from 'react-loadable'
import { LoadingIndicator } from 'inter-site-components'
import TikTok from '@interco/icons/build-v4/orangeds/LG/tik-tok'
import './Badge.scss'

type badgeProps = {
  icon: string;
  size: string;
  directory: string;
  color: string;
  styles: string;
  tiktok?: boolean;
}

const Badge = ({ icon, size, color, directory, styles, tiktok }: badgeProps) => {
  const SvgIcon = useMemo(() => (
    Loadable({
      loader: () => import(`inter-frontend-svgs/lib/${directory}/${icon}`),
      loading: () => <LoadingIndicator size='small' />,
    })
  ), [])

  return (
    <div className={`icon--badge ${styles} ${size === 'large' ? 'badge--large' : 'badge--small'} ${tiktok && 'tiktok-container'}`}>
      {tiktok
        ? <TikTok width='130' height='100' color='#161616' />
        : <SvgIcon width='130' height='100' color={color} />
      }
    </div>
  )
}

Badge.defaultProps = {
  icon: '',
  size: 'small',
  directory: 'Icons',
  color: 'orange--base',
  styles: '',
}

export default Badge
