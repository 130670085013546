import React from 'react'
import Badge from 'components/UI/Badge'

const CondicoesEspeciais = () => {
  return (
    <section id='condicoes-especiais'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <h3 className='fs-md-40'>Aqui, você tem condições especiais e vantagens que valem a pena</h3>
          </div>
        </div>
        <div className='col-12 col-lg-6 mt-3 mb-5 px-0'>
          <ul className='list-unstyled mt-3'>
            <li className='d-flex align-items-center mb-4'>
              <Badge size='large' icon='transfer' directory='Icons' />
              <p className='fs-20'>Taxas a partir de 9,0% a.a. + IPCA </p>
            </li>
            <li className='d-flex align-items-center mb-4'>
              <Badge size='large' icon='cash-house' directory='Icons' />
              <p className='fs-20'>Financiamos até 75% do seu imóvel</p>
            </li>
            <li className='d-flex align-items-center mb-4'>
              <Badge size='large' icon='calendar' directory='Icons' />
              <p className='fs-20'>Até 240 meses para pagar</p>
            </li>
            <li className='d-flex align-items-center mb-4'>
              <Badge size='large' icon='chat' directory='Icons' />
              <p className='fs-20'>Atendimento personalizado</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default CondicoesEspeciais
