import React from 'react'
import { ContentHeader } from './style'
import Icon from 'src/components/UI/MarkdownIcon'

const Hero = () => {
  return (
    <ContentHeader>
      <div className='container'>
        <div className='col-md-9 col-lg-7 pl-0 pb-2'>
          <div className='d-flex align-items-center mb-3'>
            <Icon icon='cash-house' width='39' height='30' color='white' />
            <p className='text-white fs-sm-18 fs-16 lh-20 fw-300 ml-2 mb-0'>Financiamento Imobiliário Comercial</p>
          </div>
          <h1 className='text-white fs-30 fs-md-48 fw-600'>Sua empresa está crescendo?</h1>
          <p className='text-white fs-17 lh-28 fw-400'>O Financiamento Imobiliário do Inter é ideal para quem quer conquistar um imóvel ou ampliar o patrimônio.</p>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
